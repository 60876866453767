.wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: black;
    margin: 20px 0;
    gap: 5px;
}

.number {
    height: inherit;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: #1D1B20;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #1D1B20;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
}

@media (max-width: 768px) {
    .content, .number {
        font-size: 16px;
    }
}