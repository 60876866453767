.wrapper {
    position: relative;
    width: 100%;
    max-width: 380px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 12px;
    color: #000000;
    background: #ffffff;
    margin: 0 auto;
}

.header {
    width: 100%;
    display: flex;
    padding: 20px 0;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    margin-bottom: 20px;
}

.cross_wrapper {
    position: absolute;
    top: 4%;
    left: 88%;
}

.sub_content {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    margin-bottom: 10px;
}

.sub_content_red {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #E31240;
    margin-bottom: 10px;
}

.sub_content_list {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    padding-left: 20px;
    margin-bottom: 10px;
}

ol {
    padding-left: 20px;
}

ol li {
    margin-bottom: 10px;
}

@media (max-width: 600px) {
    .wrapper {
        padding: 10px;
    }
}

@media (min-width: 320px) and (max-width: 385px){

    .cross_wrapper {
        position: absolute;
        top: 2%;
        left: 88%;
    }
}
