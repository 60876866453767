.wrapper {
    width: 96%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    background: #F8F8F8;
    z-index: 3;
}

.header {
    width: 100%;
    height: 34px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 5px 5px 0 0;
    padding: 9px;
    background: #062762;
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
}

.text_black {
    color: #1D1B20;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.text_white {
    color: #FFFFFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}
