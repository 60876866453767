.content_wrapper {
    color: #2B2B2B;
    max-width: 1250px;
}

.main_heading {
    color: #062762;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 63px;
    @media (max-width: 768px) {
        font-size: 22px;
        line-height: normal;
        margin-bottom: 10px;
    }
}

.sub_heading {
    color: #062762;
    text-align: start;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (max-width: 768px) {
        font-size: 22px;
    }
}

.call_to_action {
    color: #062762;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (max-width: 768px) {
        font-size: 18px;
    }
}

.guided_tour_img {
    object-fit: cover;
    border-radius: 30px;
    object-position: 60% 50%;
    width: 617px;
    height: auto;
    max-height: 560px;
    @media (max-width: 768px) {
        width: 100%;
        max-height: 350px;
    }
}
