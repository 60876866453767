@media (min-width: 768px) {
    .rowAgreement {
        color: #000000;
        display: flex;
        align-items: start;
        margin-bottom: 13px;
        margin-left: 10px;
    }

    .text {
        font-size: 18px;
        font-weight: 400;
        margin-right: 5px;
    }

    .underlineText {
        font-size: 18px;
        font-weight: 400;
        text-decoration: underline;
        cursor: pointer;
    }
}

@media (max-width: 768px) {
    .rowAgreement {
        color: #000000;
        display: flex;
        align-items: start;
        margin-bottom: 33px;
        margin-left: -20px;
        margin-top: 5px;
    }

    .text {
        font-size: 18px;
        font-weight: 400;
    }

    .underlineText {
        font-size: 18px;
        font-weight: 400;
        text-decoration: underline !important;
        cursor: pointer;
    }
}