.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
}

.header {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    text-align: left;
    margin-bottom: 10px;
}

.text {
    color: #2B2B2B;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 20px;
}

.image {
    width: 100%;
}