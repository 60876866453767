html {
    ::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.button_section_down {
    position: absolute;
    top: 94%;
    left: 44.5%;
    width: 220px;
    height: 50px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    gap: 10px;
    font-size: 20px;
    background: #E31340;
    color: #E1E1E1;
    z-index: 5;
    cursor: pointer;
}


.volume_button {
    position: absolute;
    top: 15%;
    left: 96%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    cursor: pointer;
}