
.box {
    margin-top: 180px;
    position: relative;
    width: 1360px;
    padding: 0px 0 50px 0;
}

@media (max-width: 768px) {
    .box {
        margin-top: 220px;
        position: relative;
        max-width: 100vw;
        padding: 0 0 30px 0;
    }
}

@media (max-width: 420px) {
    .box {
        margin-top: 220px;
        position: relative;
        width: 100%;
        padding: 0 0 30px 0;
    }
}

@media (min-width: 1023px) {
    .box {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}