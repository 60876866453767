.rowAgreement a {
    color: black;
}
@media (min-width: 768px) {
    .rowAgreement {
        color: #000000;
        display: flex;
        align-items: center;
        margin-bottom: 13px;
        margin-left: 10px;
    }

    .text {
        font-size: 18px;
        font-weight: 400;
        margin-right: 5px;
    }

    .underlineText {
        font-size: 18px;
        font-weight: 400;
        text-decoration: underline;
        cursor: pointer;
    }
}

@media (max-width: 1280px) {
    .rowAgreement {
        color: #000000;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0 0 15px 15px;
    }

    .text {
        font-size: 14px;
        font-weight: 400;
        margin-right: 5px;
    }

    .underlineText {
        font-size: 14px;
        font-weight: 400;
        text-decoration: underline;
        cursor: pointer;
    }
}
