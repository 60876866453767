.locSelect {
    margin-right: 10px;
    :global .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
        color: white;
        border: none;
        &:hover {
            border: none;
            background-color: transparent;
        }
        &[aria-expanded="true"] {
            background-color: transparent;
        }
    }
}
