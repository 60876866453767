.wrapper {
    display: flex;
    padding: 18px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 10px;
    background: #F8F8F8;
    width: 92vw;
    margin: 0 auto 15px auto;
}


.header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
}

.header_text {
    color: #062762;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    @media (max-width: 768px) {
        font-size: 18px;
    }
}

.content {
    width: 100%;
    display: flex;
    padding: 0 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000000;
    margin-bottom: 15px;
}

.mapped_wrapper {
    width: 100%;
    margin-bottom: 8px;
}

.content_inner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
}

.red_text {
    color: #E31240;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.red_text_bottom {
    color: #E31240;
    font-size: 13px;
    cursor: pointer;
}

.inner_text {
    margin-bottom: 10px;
}

.footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

@media (min-width: 1023px) {
    .wrapper {
        display: flex;
        width: 100%;
        padding: 20px;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto 15px auto;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        border-radius: 10px;
        background: #F8F8F8;
    }

    .inner_text {
        margin: 0;
    }

    .header {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
    }

    .content {
        width: 50%;
        padding: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #000000;
        margin-bottom: 15px;
    }

    .red_text_bottom {
        margin-top: 5px;
        color: #E31240;
        font-size: 16px;
    }

    .red_text {
        font-size: 16px;
    }

    .footer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
}


@media (min-width: 2000px) {
    .wrapper {
        margin-bottom: 30px;
    }
}