.wrapper {
    display: flex;
    width: 92vw;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    background: #F8F8F8;
    margin-bottom: 15px;
    text-align: center;
}

.wrapper_travel {
    margin-top: 90px;
    display: flex;
    width: 92vw;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    background: #F8F8F8;
    margin-bottom: 15px;
    text-align: center;
}

.wrapper_map {
    margin-top: 90px;
    display: flex;
    width: 92vw;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    background: #F8F8F8;
    margin-bottom: 15px;
    text-align: center;
}

.content {
    display: flex;
    width: 100%;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    background: #F8F8F8;
    margin-bottom: 15px;
}

.header {
    color: #062762;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}

.content_text {
    color: #062762;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.sub_context {
    color: #2B2B2B;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.buttons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.buttons_map {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.image_button {
    width: 173px;
    height: 51px;
}

.addvert_sub_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


@media (min-width: 1023px) {
    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 74vw;
        height: 380px;
        padding: 16px;
        align-items: center;
        gap: 20px;
        border-radius: 10px;
        background: #F8F8F8;
        margin-bottom: 15px;
    }

    .addvert_sub_wrapper {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 10px;
    }

    .wrapper_travel {
        margin-top: -25px;
        display: flex;
        width: 1350px;
        padding: 16px;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        border-radius: 10px;
        background: #F8F8F8;
        margin-bottom: 15px;
        text-align: center;
    }

    .buttons {
        gap: 30px;
        width: 23%;
    }

    .buttons_map {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .content_text {
        font-size: 20px;
        text-align: center;
    }

    .header {
        font-size: 30px;
    }

    .sub_context {
        font-size: 17px;
        flex-wrap: wrap;
        text-align: center;
    }
}

@media (min-width: 2000px) {
    .wrapper {
        width: 56vw;
    }
}

@media (min-width: 800px) and (max-width: 1900px) {
    .buttons {
        width: 35%;
    }
}