.wrapper {
    position: fixed;
    width: 100%;
    height: 60px;
    top: 0;
    left: 0;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    background: #000000;
}

.sub_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1000px;
    padding-left: 10px;
    padding-right: 10px;
}

.button_back {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    z-index: 5;
}

.text {
    color: #FFFFFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    flex: 1;
    margin-left: -40px;
}

.text_back {
    color: #FFFFFF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
}
