.container {
    max-width: 1360px;
    margin: auto;
    position: relative;
    margin-bottom: 50px;
}

.checkBoxWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -45px;
    gap: 10px;
}

.checkBoxText {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.box {
    height: 270px;
    background-color: #F8F8F8;
    border-radius: 10px;
    padding: 48px 49px 69px 50px;
    margin: auto;
}

.groupTitleBox {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 40px 0 40px 50px;
}

.groupTitle {
    color: #062762;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0px;
    margin-left: 15px;
}

.textField {
    width: 360px;
    height: 56px;
}

.question {
    width: 100%;
}

.textfieldBox {
    margin: 40px 25px 0 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.title {
    color: #062762;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: flex-start;
}

.innerBoxRow {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.innerBox {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.thanks_text {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #062762;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    line-height: 30px;
    margin-bottom: 50px;
}

.header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #062762;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    padding-top: 30px;
    padding-left: 25px;
}

.textfieldRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.footerItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0 50px 50px;
}

.buttonToGo {
    margin: 50px 0 0 130px !important;
    width: 138px;
    height: 50px;
}

@media (max-width: 1320px) and (min-width: 768px) {
    .container {
        max-width: 1320px;
        margin: auto;
        position: relative;
        margin-bottom: 50px;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
}


@media (max-width: 768px) {
    .container {
        max-width: 380px;
        margin: auto;
        position: relative;
        margin-bottom: 0px;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .groupTitleBox {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 20px 0 20px 20px;
        width: 328px;
    }

    .box {
        margin: auto;
        height: 640px;
        background-color: #F8F8F8;
        border-radius: 10px;
        padding: 19px 17px 35px 15px !important;
        margin-bottom: 100px;
    }

    .textField {
        width: 374px;
        height: 56px;
    }

    .textfieldBox {
        margin: 30px 0 10px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .title {
        color: #062762;
        font-size: 22px;
        font-weight: bold;
        display: flex;
        align-items: flex-start;
    }

    .innerBoxRow {
        display: flex;
        flex-direction: column;
    }

    .innerBox {
        display: flex;
        flex-direction: column;
    }

    .textfieldRow {
        display: flex;
        flex-direction: column;
    }

    .footerItems {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 0 0 50px;
        margin-bottom: 15px;
    }

    .buttonToGo {
        margin: 20px 0 !important;
        width: 212px;
        height: 50px;
    }
}

@media (max-width: 768px) {
    .groupTitleBox {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 20px 0 20px 10px;
        width: 328px;
    }

    .footerItems {
        flex-direction: column;
    }

    .innerBoxRow {
        margin-bottom: 100px;
    }
}

@media (min-width: 1023px) {
    .container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        max-width: 79vw;
        margin: 0 auto 50px auto;
        position: relative;
    }


    .wrapper {
        width: 79vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .innerBox {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .box {
        width: 1350px;
        height: 410px;

    }

    .buttonToGo {
        width: 252px;
        height: 50px;
    }

    .footerItems {
        width: 100%;
        margin-left: -25px;
    }


    .innerBox {
        width: auto;
    }
}

@media (min-width: 1200px) {
    .header {
        margin-left: 44%;
    }

}

@media (min-width: 2000px) {
    .header {
        margin-left: 60%;
    }
}

@media (min-width: 320px) and (max-width: 420px) {
    .textField {
        width: 320px;
    }

    .textfieldBox {
        width: 320px;
    }

    .checkBoxText {
        font-size: 16px;
    }
}