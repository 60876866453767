.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
    margin-top: 150px;

}

.toggle_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.text_content {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

@media (min-width: 1023px) {
    .wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 200px;
        margin-top: 150px;
    }
}
