.wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    color: #1D1B20;
    gap: 10px;
}

.transport_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.numbers {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
}

.text {
    color: #1D1B20;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.schedule_text {
    color: #1D1B20;
    font-family: Montserrat, serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}