.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 15px;
    background: #FFFFFF;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    color: #1D1B20;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 10px;
}

.header_modified {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 20px 0 20px 20%;
    color: #000;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.main_text {
    color: #1D1B20;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.iconsWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.wrapper_mutated {}

.list_content {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

@media (min-width: 1023px) {
    .wrapper {
        position: relative;
        width: 1200px;
        color: #000000;
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        text-align: left;

    }
    .main_text {
        font-size: 20px;
        font-weight: 400;
        line-height: 25px;
        text-align: left;
    }
    .header, .iconsWrapper {
        color: #000;
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        justify-content: center;
    }

    .wrapper_mutated {
        width: 1250px;
    }

    .header {
        width: 90%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .cross_wrapper {
        position: absolute;
        top: 12%;
        left: 95%;
    }
}