.wrapper {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 15px 15px 15px 15px;
    background: #E8EBF0;
    margin-top: 20px;
    margin-bottom: 20px;
}

.wrapper_group {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 15px 15px 15px 15px;
    background: #E8EBF0;
    margin-top: 20px;
}

.wrapper_travel {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 15px 35px 15px 15px;
    background: #E8EBF0;
    margin-top: 20px;
}

.sub_header {
    width: 100%;
}

.header {
    color: #000;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 20px;
}

.description {
    width: 100%;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

.click_description {
    color: #000000;
    cursor: pointer;
}

@media (min-width: 1023px) {
    .wrapper {
        width: 1250px;
        height: 350px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        padding: 15px 15px 15px 15px;
        background: #E8EBF0;
        margin-top: 20px;
    }

    .description {
        font-size: 20px;
        font-weight: 500;
        line-height: 24.38px;
        text-align: center;
        text-decoration: none;
    }

    .wrapper_group {
        width: 1360px;
        margin: 20px auto 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        padding: 15px 15px 15px 15px;
        background: #E8EBF0;
    }

    .wrapper_travel {
        width: 1350px;
        margin: 20px auto 50px auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        padding: 15px 15px 15px 15px;
        background: #E8EBF0;
    }

    .container {
        margin-top: 50px;
    }
}
