.logoWrapper {
top: 90px;
left: 320px;
margin: 10px 1.5vw 10px 0;
display: flex;
}
.logo {
width: 199px;
height: 80px;
}

@media (max-width: 1280px) {
.logoWrapper {
top: 90px;
left: 320px;
margin: 10px 1.5vw 10px 0;
display: flex;
}
.logo {
width: 199px;
height: 80px;
}}

@media (max-width: 768px) {
.logoWrapper {
top: 90px;
left: 320px;
margin: 10px 20px 10px 0;
display: flex;
}
.logo {
width: 149.1px;
height: 60px;
}}
