.wrapper {
    width: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: #000000;
    border: 1px solid #B9B9B9;
    background: #FFF;
    margin: 0 auto 10px auto;
}

.header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


.icon {
    width: 109.34px;
    height: 44px;
    margin: 10px 20px;
}

.wrapper_part_one {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.personal_data {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.personal_data_desc {
    width: 43%;
    color: #000;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.hoziontal_line {
    width: 90px;
    height: 1px;
    border: 1px dashed #E1E1E1;
    stroke-width: 1px;
    margin: 10px 0;
}

.red_button_change {
    color: #E6264C;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-left: 10px;
    cursor: pointer;
}

.personal_data_props_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 10px 0;
}

.personal_data_props_header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #79747E;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.personal_data_props_content {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.wrapper_price {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-left: 20px;
    margin-bottom: 20px;
}

.sub_wrapper_price {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.price_description {
    width: 100%;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.price {
    width: 100%;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    text-align: right;
    padding-right: 20px;
}

.horizontal_line_end {
    width: 88%;
    height: 1px;
    border: 1px dashed #383838;
    stroke-width: 1px;
    margin: 10px 0;
}

@media (max-width: 390px) {
    .wrapper {
        width: 350px;
        margin: 0 0 10px 0;
    }
}