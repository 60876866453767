.wrapper {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #000;
    padding: 20px;
    border: 1px solid #E1E1E1;
    background: #FFFFFF;
}

.left_description {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
}

.line {
    width: 1px;
    height: 25px;
    border-radius: 10px;
    background: #B9B9B9;
}

@media (min-width: 1023px) {
    .wrapper {
        width: 150px;
        height: 880px;
        flex-direction: column;
        justify-content: flex-start;
        gap: 40px;
    }

    .line {
        width: 25px;
        height: 1px;
    }
}