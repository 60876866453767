
.wrapper-usual {
    cursor: pointer;
}
.gallery_wrapper {
    width: 100%;
    margin-top: -60px;
}

.more_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.header_text {
    width: 100%;
    color: #2B2B2B;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 300px;
}

.wrapper_unusual {
    width: 390px;
    position: relative;
    @media (max-width: 768px) {
        width: 92dvw;
    }
}

.images {
    margin: 2px;
    object-fit: cover;
    border-radius: 10px;
}

.basic_image {
    width: 90vw;
    height: 165px;
    border-radius: 10px;
    cursor: pointer;
    @media (max-width: 768px) {
        width: 92dvw;
    }
}


.image_1 {
    width: 50%;
    height: 130px;
}

.image_2 {
    position: relative;
    top: 60px;
    width: 47%;
    height: 189px;
}
.image_3 {
    width: 50%;
    height: 130px;
}
.image_4 {
    width: 47%;
    height: 69px;
}

.text_red {
    color: #E31240
}


@media (min-width: 769px) {
    .basic_image {
        width: 400px;
        height: 340px;
        border-radius: 10px;
    }

    .wrapper_unusual {
        position: relative;
        width: 400px;
        height: 340px;
    }

    .gallery_wrapper {
        width: 100%;
        height: 340px;
    }
    .wrapper-usual {
        margin-top: 30px;
        position: relative;
        width: 400px;
        height: 500px;
    }
    .image_1 {
        width: 50%;
        height: 175px;
    }

    .image_2 {
        position: relative;
        top: 60px;
        width: 47%;
        height: 235px;
    }
    .image_3 {
        width: 50%;
        height: 160px;
    }
    .image_4 {
        width: 47%;
        height: 100px;
    }
}

@media (min-width: 1023px) {
    .header_text {
        color: #1D1B20;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}
@media (max-width: 390px) {
    .wrapper_unusual {
        width: 350px;
    }
}