.wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.images {
    width: 90vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (min-width: 1023px) {
    .images {
        width: 1600px;
        height: 1000px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 120px;
    }
}