.jacobs_bell_content_wrapper {
    display: flex;
    max-width: 1360px;
    gap: .7rem;
    flex-direction: row;
    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.img_bell {
    width: 490px;
    height: 525px;
    object-fit: cover;
    object-position: 0 0;
    border-radius: 10px;
    @media (max-width: 768px) {
        width: 100%;
        height: auto;
        max-height: 400px;
    }
    @media (max-width: 420px) {
        max-height: 300px;
    }
}

.tower_bell_text_content {
    padding: 2rem 0;
    max-width: 870px;
}

.secondary_section_item {
    margin: 0 0 1.5rem 1rem;
    white-space: pre-line;
}
