@media (min-width: 768px) {
    .mobile_only {
        display: none;
    }

    .icon {
        margin: auto;
        padding: 1px 8px;
    }

    .box {
        padding: 10px 0 20px 0;
    }

    .iconGrey {
        position: relative;
        height: 32px;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1px 8px 1px 5px;
        font-size: 16px;
        border: 2px solid #B9B9B9 !important;
        color: #B9B9B9;
        border-radius: 16px;
    }

    .isTabActive {
        border: 2px solid #062762 !important;
        color: #062762;
    }

    .isTabDone {
        border: 2px solid #051C4766 !important;
        color: #051C4766;
    }

    .title {
        color: #062762;
        font-size: 24px;
        font-weight: bold;
        padding: 40px 50px 30px 50px;
        display: flex;
        align-items: center;
    }

    .titleText {
        color: #062762;
        font-size: 24px;
        font-weight: bold;
    }

    .title_wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-left: 50px;
    }

    .wrapper {
        width: 100%;
        max-width: 1360px;
    }

    .panelWrapper {
        width: 100%;
        margin: 0 auto;
    }
}

@media (min-width: 768px) and (max-width: 1280px) {

    .title {
        color: #062762;
        font-size: 24px;
        font-weight: bold;
        padding: 40px 50px 30px 10px;
        display: flex;
        align-items: center;
    }
}

@media (max-width: 768px) {
    .icon {
        margin: auto;
    }

    .box {
        margin: 15px auto auto;
    }

    .title_wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .title_mutated {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .wrapper {
        position: fixed;
        top: 80px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
        z-index: 5;
        background: #fff;
    }

    .wrapper_mobile {
        position: fixed;
        top: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        z-index: 5;
        background: #ffffff;
    }

    .relative {
        position: relative;
        top: -30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        z-index: 5;
        background: #fff;
    }

    .iconGrey {
        position: relative;
        height: 32px;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 3px solid #B9B9B9 !important;
        color: #B9B9B9;
        border-radius: 16px;
    }

    .isTabActive {
        border: 2px solid #062762 !important;
        color: #062762;
    }

    .isTabDone {
        border: 2px solid #051C4766 !important;
        color: #051C4766;
    }

    .panelWrapper {
        margin-right: 30px;
        margin-top: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .title {
        color: #062762;
        font-weight: bold;
        padding: 20px 24px 20px 15px;
        display: flex;
        align-items: center;
    }

    .titleText {
        color: #062762;
        font-size: 20px;
        font-weight: bold;
        max-width: 250px;
    }
}


@media (min-width: 1023px) {
    .panelWrapper {
        margin-right: 30px;
        margin-top: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .relative {
        position: relative;
        top: -30px;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        z-index: 5;
        background: #fff;
    }

    .title_mutated {
        margin-right: 70%;
        margin-bottom: 30px;
    }


    .box {
        display: flex;
        margin: 15px auto auto;
    }
}