.wrapper {
    width: 950px;
    height: 520px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

.sub_big_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sub_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sub_down_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.description_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.person {
    width: 330px;
    height: 330px;
    margin-left: -18%;
}

.description {
    color: #1D1B20;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
}