.container {
margin: auto;
position: relative;
max-width: 100vw;
}
.box {
max-width: 100%;
position: relative;
margin: auto;
}
.imageContainer {
max-width: 100%;
height: 100%;
overflow: hidden;
position: relative;
}
.image {
width: 100%;
height: 100%;
object-fit: cover;
object-position: 0px 140px;
transform: scale(1.6);
}
.overlayPic {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: #06276299;
background-repeat: no-repeat;
z-index: 1;
overflow: hidden;
}
.overlayText {
font-weight: bold;
color: #FFFFFF;
z-index: 2;
font-size: 52px;
}
.overlay {
position: absolute;
top: 55%;
left: 50%;
transform: translate(-50%, -50%);
width: 1360px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
z-index: 2;
}
.button{
width: 218px;
height: 50px;
}
.error{
margin: 120px 0;
}

@media (max-width: 768px) {
.container {
margin: auto;
position: relative;
width: 412px;
}
.box {
padding-top: 57px;
width: 412px;
height: 100vh;
position: relative;
margin: auto;
}
.imageContainer {
width: 100%;
height: 100%;
overflow: hidden;
position: relative;
}
.image {
width: 100%;
height: 100%;
object-position: -315px 20px;
}
.overlayPic {
padding-top: 57px;
position: absolute;
top: 0;
left: 0;
width: 412px;
height: 100vh;
background-color: #06276299;
background-repeat: no-repeat;
z-index: 1;
overflow: hidden;
}
.overlayText {
font-weight: bold;
color: #FFFFFF;
z-index: 2;
font-size: 26px;
width: 300px;
text-align: center;
}
.overlay {
position: absolute;
top: 53%;
left: 50%;
transform: translate(-50%, -50%);
width: 412px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
z-index: 2;
}
.button{
width: 218px;
height: 50px;
}
.error{
margin: 50px 0;
}}
