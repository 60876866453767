.removedMarginTop {
    margin-top: 0 !important;
    height: 320px !important;
    .imageContainer, .overlay {
        height: 100%;
    }
    .imageContainer>img {
        height: 320px;
    }
}

@media (min-width: 769px) {
    .box {
        position: relative;
        height: 600px;
        margin: auto;
        overflow: hidden;
        max-width: 100vw;
    }

    .imageContainer {
        width: 100%;
        height: 100%;
        overflow: hidden;
        margin: auto;
    }

    .image {
        margin: auto;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        overflow: hidden;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(5, 28, 71, 0.4);
        background-repeat: no-repeat;
        overflow: hidden;
    }

    .titles {
        position: absolute;
        top: 62%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #FFFFFF;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .subTitleKrovy {
        font-size: 52px;
        font-weight: bold;
    }

    .subTitle {
        font-size: 32px;
        font-weight: bold;
    }

    .buttonToGo {
        width: 311px;
        height: 50px;
        background-color: #E31340;
        color: white;
    }

    .buttonText {
        font-size: 16px;
        color: white;
    }
}

@media (max-width: 1280px) {
    .titles {
        position: absolute;
        top: 62%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #FFFFFF;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .subTitleKrovy {
        font-size: 52px;
        font-weight: bold;
    }

    .subTitle {
        font-size: 32px;
        font-weight: bold;
    }

    .buttonToGo {
        width: 311px;
        height: 50px;
        background-color: #E31340;
        color: white;
    }

    .buttonText {
        font-size: 16px;
        color: white;
    }
}

@media (max-width: 990px) {
    .titles {
        position: absolute;
        top: 62%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #FFFFFF;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .subTitleKrovy {
        font-size: 52px;
        font-weight: bold;
    }

    .subTitle {
        font-size: 32px;
        font-weight: bold;
    }

    .buttonToGo {
        width: 311px;
        height: 50px;
        background-color: #E31340;
        color: white;
    }

    .buttonText {
        font-size: 16px;
        color: white;
    }
}

@media (max-width: 768px) {
    .box {
        position: relative;
        width: 100dvw;
        height: 400px;
        margin: 80px auto auto;
    }

    .image {
        width: 100dvw;
        height: 400px;
        object-fit: cover;
        object-position: top;
    }

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-color: rgba(5, 28, 71, 0.4);
        z-index: 1;
    }

    .titles {
        padding: 0 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #FFFFFF;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .subTitleKrovy {
        margin-top: 5px;
        font-size: 26px;
        font-weight: bold;
    }

    .subTitle {
        font-size: 26px;
        font-weight: bold;
    }

    .buttonToGo {
        width: 311px;
        height: 50px;
        background-color: #E31340;
        color: white;
    }

    .buttonText {
        font-size: 16px;
        color: white;
    }
}

@media (max-width: 420px) {
    .titles {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #FFFFFF;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media (min-width: 1023px) {
    .image {
        object-position: center 46%;
    }
}