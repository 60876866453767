.container {
    display: flex;
    overflow-x: scroll;
    gap: .3rem;
    padding: .5rem 1rem;
    margin: 1rem 0;
    height: min-content;

    ::-webkit-scrollbar {
        display: none;
    }

    img {
        width: 180px;
        min-width: 180px;
        height: auto;
        max-height: 214px;
        object-fit: cover;
        border-radius: 10px;
    }

}
