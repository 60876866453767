.wrapper {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.text_content {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    padding: 5px;
}

.time_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.red_text {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
    padding: 5px;
    color: #E31240;
}

.red_text_right {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 5px;
    color: #E31240;
    text-align: end;
}
