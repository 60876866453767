.container {
margin: auto;
position: relative;
max-width: 100vw;
}
.box {
max-width: 100%;
height: 100%;
position: relative;
margin: auto;
}
.imageContainer {
max-width: 100%;
height: 100%;
overflow: hidden;
position: relative;
}
.image {
width: 100%;
height: 100%;
object-position: 0px 140px;
transform: scale(1.6);
}
.overlayPic {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: #06276299;
background-repeat: no-repeat;
z-index: 1;
overflow: hidden;
}
.overlayText1 {
font-weight: bold;
color: #FFFFFF;
z-index: 2;
font-size: 52px;
}
.overlayText2 {
font-weight: bold;
color: #FFFFFF;
z-index: 2;
font-size: 52px;
}
.overlay {
position: absolute;
top: 43%;
left: 50%;
transform: translate(-50%, -50%);
width: 1360px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
z-index: 2;
}

.to_home {
    width: 200px;
    height: 50px;
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    background: #E31240;
}


@media (max-width: 768px) {
.container {
margin: auto;
position: relative;
width: 412px;
}
    .description {
        font-weight: 400;
        color: #FFFFFF;
        z-index: 2;
        font-size: 18px;
        text-align: center;
        width: 100%;
        margin-top: 50px;
        padding: 0 20px;
    }
    .to_home {
        width: 200px;
        height: 50px;
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 30px;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 400;
        background: #E31240;
    }
.box {
padding-top: 45px;
width: 412px;
height: 100vh;
position: relative;
margin: auto;
}
.imageContainer {
width: 100%;
height: 100vh;
overflow: hidden;
position: relative;
}
.image {
width: 100%;
height: 100vh;
object-position: -315px 40px;
}
.overlayPic {
padding-top: 45px;
position: absolute;
top: 0;
left: 0;
width: 412px;
height: 100vh;
background-color: #06276299;
background-repeat: no-repeat;
z-index: 1;
overflow: hidden;
}
.overlayText1 {
font-weight: bold;
color: #FFFFFF;
z-index: 2;
font-size: 26px;
text-align: center;
width:250px;
    margin-bottom: 50px;
}
.overlayText2 {
font-weight: bold;
color: #FFFFFF;
z-index: 2;
font-size: 26px;
}
.overlay {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
width: 412px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
z-index: 2;
}}
