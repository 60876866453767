.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 150px 15px 0 15px;
}

.header {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    text-align: left;
    margin-bottom: 10px;
}

.image {
    height: 400px;
    margin-bottom: 30px;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sub_header {
    width: 100%;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    margin-bottom: 15px;
}


.text {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.person {
    width: 100%;
    height: 320px;
    object-fit: cover;
}

.description {
    color:  #1D1B20;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.sub_description {
    color:  #1D1B20;
    font-family: Montserrat, sans-serif;
    font-size: 15px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
}

.rec_header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.block_header {
    width: 100%;
    color:  #1D1B20;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.block_bold {
    color: #062762;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.info_wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: .5rem;
    padding-bottom: 40px;
}

@media (min-width: 1023px) {
    .image {
        width: 100vw;
        height: 40vh;
        margin-bottom: 30px;
        object-fit: cover;
    }
    .info_wrapper {
        grid-template-columns: repeat(4, 280px);
        place-content: center;
        gap: 15px;
    }
}