.wrapper {
    position: fixed;
    top: 15%;
    left: 5%;
    width: 90%;
    height: 600px;
    border-radius: 20px;
    background: #FFFFFF;
    padding: 30px;
    z-index: 9;
}

.header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.header_text {
    width: 100%;
    text-align: left;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
}

.sub_header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 20px;
}

.sub_header_text_one {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.sub_header_text_two {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.img_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.cross {
    position: absolute;
    top: 15px;
    left: 86%;
}


@media (min-width: 1023px) {
    .wrapper {
        position: fixed;
        top: 15%;
        left: 37%;
        width: 400px;
        height: 600px;
        border-radius: 20px;
        background: #FFFFFF;
        padding: 30px;
        z-index: 9;
    }
}
