.wrapper {
    margin-top: 150px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2B2B2B;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 30px 0;
}

.line {
    width: 100vw;
}

.arrow_down {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}