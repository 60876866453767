.wrapper {
    width: 96vw;
    margin-top: 10px;
    margin-bottom: 16px;
    display: grid;
    grid-template-columns: 1fr;
    padding: 16px 20px;
    border-radius: 18px;;
    /*background: rgba(6, 39, 98, 0.80);*/
}

.debt_counter {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    margin-bottom: 10px;
}

.money_count {
    color: #062762;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    margin-bottom: 5px;
    text-align: left;
}

.text_right {
    text-align: right;
}

.sub_text {
    color: #E31240;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.sub_text_left {
    color: #062762;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: left;
}

.text_red {
    color: #E31240
}


.money_count_right {
    color: #1D1B20;
    text-align: end;
}

.subscription {
    color: #1D1B20;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


@media (min-width: 1023px) {
    .wrapper {
        margin-top: 50px;
        width: 35vw;
    }
}
