.wrapper {
    position: relative;
    width: 1400px;
    height: 620px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    color: black;
    padding: 40px;
}

.header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 39.01px;
    text-align: center;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.text {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 20px 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;

}


.ol_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
}

.cross_wrapper {
    position: absolute;
    top: 8%;
    left: 95%;
}
