.wrapper {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 20px;
    border: 3px solid #E8EBF0;
    background: #E8EBF0;
    margin-bottom: 10px;
}