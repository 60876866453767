.technology_partner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    margin-right: 30px;
}

.technology_partner_text {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 11.72px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.image {
    width: 140px;
}