.mobile_only {
    display: none;
}

.removeMarginTop {
    margin-top: 0 !important;
}

.box {
    position: relative;
    max-width: 100vw;
    height: 1200px;
    margin: auto;
    overflow: hidden;
}

.imageContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: auto;
    overflow: hidden;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}

.overlayPic {
    padding: 70px 0 0 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #0627624D;
    background-repeat: no-repeat;
    z-index: 1;
    overflow: hidden;
}

.button {
    width: 308px;
    height: 70px;
}

.buttonText {
    color: #FFFFFF;
    font-size: 22px;
    cursor: pointer;
    flex-wrap: nowrap;
    margin: 0 15px 0 0;
    line-height: 27px;
}

.overlay {
    position: absolute;
    top: 18%;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.text {
    font-weight: bold;
    color: #FFFFFF;
    font-size: 52px;
    z-index: 1;
    white-space: normal;
    overflow: hidden;
    line-height: 63px;
}

.textContainer {
    max-width: 805px;
    height: 126px;
    text-align: center;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 200px;
}

.iconTicket {
    cursor: pointer;
    width: 36px;
}

.overlay {
    position: absolute;
    top: 5%;
    left: 0;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.image {
    width: 100vw;
    height: 1200px;
    object-fit: cover;
    overflow: hidden;
}

.imageContainer {
    width: 100vw;
    height: 100%;
    overflow: hidden;
    margin: auto;
}

@media (max-width: 768px) {
    .mobile_only {
        display: block;
    }

    .desktop_only {
        display: none;
    }

    .box {
        position: relative;
        width: 100%;
        height: 470px;
        margin: 75px auto auto;
    }

    .image {
        width: 100%;
        height: 470px;
        object-fit: cover;
        object-position: center;
    }

    .imageContainer {
        width: 100%;
        height: 100%;
        margin: auto;
        overflow: hidden;
    }

    .overlayPic {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #0627624D;
        background-repeat: no-repeat;
        z-index: 1;
    }

    .button {
        width: 309.73px;
        height: 60px;
        margin: 20px 0 0 30px;
    }

    .buttonText {
        color: #FFFFFF;
        font-size: 20px;
        font-weight: 400;
        margin: 0 15px 0 0;
        text-align: center;
        cursor: pointer;
        flex-wrap: nowrap;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 35px;
        z-index: 2;
    }

    .text {
        color: #FFF;
        text-align: center;
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
    }

    .sub_text {
        color: #FFF;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
    }

    .iconTicket {
        cursor: pointer;
        width: 32px;
    }
}

@media (max-width: 420px) {
    .mobile_only {
        display: block;
    }

    .desktop_only {
        display: none;
    }

    .box {
        position: relative;
        width: 100%;
        height: 470px;
    }

    .image {
        width: 100%;
        height: 470px;
        object-fit: cover;
        object-position: center;
    }
}


@media (min-width: 1023px) {
    .box {
        height: 600px;
        margin-bottom: 150px;
    }
    .overlayPic {
        height: 600px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.60) -17.44%, rgba(0, 0, 0, 0.10) 100%);
    }

    .imageContainer {
        height: 600px;
    }

    .image {
        height: 600px;
        object-position: center 66%;
    }

    .textContainer {
        margin-bottom: 50px;
    }

    .text {
        color: #FFF;
        text-align: center;
        font-size: 52px;
        font-style: normal;
        font-weight: 700;
        line-height: 63px;
    }

    .sub_text {
        color: #FFF;
        font-size: 52px;
        font-style: normal;
        font-weight: 400;
        line-height: 63px;
    }
}