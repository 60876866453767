.container {
    position: relative;
    margin: auto;
    background-color: #FFFFFF;
    max-width: 100vw;
}

.mainBox {
    position: relative;
    margin: auto;
    max-width: 1360px;
    height: 533px;
    background-color: #F8F8F8;
    margin-top: 40px;
    border-radius: 10px;
}

.titleBox {
    position: relative;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 56px 0 46px 0;
}

.titleString {
    color: #062762;
    font-size: 24px;
    font-weight: 600;
}

.icon {
    width: 32px;
    margin: 0 15px 0 0;
}

.subtitleBox {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    color: #000000;
}

.subtitleText1 {
    text-align: center;
    width: 656px;
    height: 64px;
}

.subtitleText2 {
    font-weight: 600;
    margin: 0 5px 0 10px;
}

.line {
    width: 889px;
    height: 0px;
    border: 1px dashed #707070;
    opacity: 1;
    margin: 0 auto;
    margin-top: 24px;
}

.noticeBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.notice {
    display: flex;
    align-items: flex-start;
    width: 889px;
    height: 48px;
    margin: 13px 0 0 0;
}

.text {
    font-size: 20px;
    color: #000000;
}

.text2 {
    margin: 0 5px 0 5px;
    font-weight: 600;
}

.text3 {
    font-size: 20px;
    margin: 0 5px 0 5px;
    color: #E31340;
    cursor: pointer;
}

.text4 {
    margin: 0 5px 0 5px;
    font-weight: 600;
}

.priceContainer {
    display: flex;
    flex-direction: column;
    padding: 0 10px 0 0;
}

.fullPrice {
    font-size: 16px;
    font-weight: 400;
}

.price {
    font-size: 20px;
    font-weight: bold;
}

.buttonBox {
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1280px) {
    .container {
        position: relative;
        margin: auto;
        background-color: #F8F8F8;
    }

    .mainBox {
        position: relative;
        margin: auto;
    }
}

@media (max-width: 990px) {
    .ConditionBoxRow {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
    }
}

@media (max-width: 768px) {
    .container {
        position: relative;
        margin: auto;
        background-color: #FFFFFF;
    }

    .mainBox {
        position: relative;
        margin: auto;
    }

    .titleBox {
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0px;
    }

    .titleString {
        color: #062762;
        font-size: 22px;
        font-weight: 600;
    }

    .subtitleBox {
        margin: auto;
        max-width: 352px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #000000;
        padding: 10px 30px;
    }

    .subtitleText1 {
        text-align: center;
    }

    .subtitleText2 {
        font-weight: 600;
        margin: 0 5px 0 10px;
    }

    .line {
        width: 362px;
        height: 0px;
        border: 1px dashed #707070;
        opacity: 1;
        margin: auto;
        margin: 10px 0;
    }

    .noticeBox {
        margin: auto;
        width: 362px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
    }

    .notice {
        width: 362px;
        margin: 10px 0;
    }

    .text {
        font-size: 16px;
        color: #000000;
    }

    .text2 {
        font-weight: 600;
    }

    .text3 {
        font-size: 16px;
        color: #E31340;
        cursor: pointer;
        white-space: nowrap;
    }

    .text4 {
        margin: 0 5px 0 5px;
        font-weight: 600;
    }

    .textBlock {
        width: 270px;
    }

    .line2 {
        width: 362px;
        height: 0px;
        border: 1px dashed #707070;
        opacity: 1;
        margin: auto;
        margin: 60px 0 0 0;
    }

    .priceContainer {
        display: flex;
        flex-direction: column;
        padding: 0 10px 0 0;
    }

    .fullPrice {
        font-size: 16px;
        font-weight: 400;
    }

    .price {
        font-size: 20px;
        font-weight: bold;
    }

    .buttonBox {
        padding: 10px 0 -30px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 420px) {
    .container {
        background-color: #FFFFFF;
    }

    .titleString {
        color: #062762;
        font-size: 20px;
        font-weight: 600;
    }

    .icon {
        width: 24px;
    }

    .subtitleBox {
        margin: auto;
        max-width: 352px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #000000;
        padding: 10px 10px;
    }

    .subtitleText1 {
        text-align: center;
    }

    .subtitleText2 {
        font-weight: 600;
        margin: 0 5px 0 10px;
    }

    .line {
        position: relative;
        margin: auto;
        width: 320px;
        height: 0px;
        border: 1px dashed #707070;
        opacity: 1;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 50px;
    }

    .noticeBox {
        margin: auto;
        width: 362px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
    }

    .notice {
        width: 362px;
        margin: 10px 0;
    }

    .text {
        font-size: 16px;
        color: #000000;
    }

    .text2 {
        font-weight: 600;
    }

    .text3 {
        font-size: 16px;
        color: #E31340;
        cursor: pointer;
        white-space: nowrap;
    }

    .text4 {
        margin: 0 5px 0 5px;
        font-weight: 600;
    }

    .textBlock {
        width: 270px;
    }

    .line2 {
        position: relative;
        margin: auto;
        width: 320px;
        height: 0px;
        border: 1px dashed #707070;
        opacity: 1;
        margin-top: 60px;
        margin-right: 50px;
    }

    .priceContainer {
        display: flex;
        flex-direction: column;
        padding: 0 10px 0 0;
    }

    .fullPrice {
        font-size: 16px;
        font-weight: 400;
    }

    .price {
        font-size: 20px;
        font-weight: bold;
    }

    .buttonBox {
        padding: 10px 0 -30px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.addvert {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}