.wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 50px 1fr;
    justify-content: center;
    place-items: center;
}

.line {
    width: 100%;
    height: 2px;
    background: #B9B9B9;
}

.text {
    color: #B9B9B9;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
