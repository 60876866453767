.wrapper {
    position: relative;
    width: 100vw;
    height: 99vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
    overflow: hidden;
}

.image {
    width: 100%;
    height: 260px;
    object-fit: cover;
    user-select: none;
}

.navigation {
    top: 50%;
    left: 3%;
    position: absolute;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (min-width: 1023px) {
    .image {
        width: auto;
        height:auto;
        max-width: 1100px;
        max-height: 680px;
        flex-shrink: 0;
    }
}