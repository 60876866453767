.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dialog {
    border-radius: 10px;
    height: 450px;
    min-height: 450px;
    margin-top: 20px;
    background-color: #FFFFFF;
    border: 1px solid #E1E1E1;
    color: #000;
    padding: 10px 14px;
    position: relative;
    @media (max-width: 768px) {
        min-height: 400px;
        height: 400px;
    }
}

.calendar {
    width: 100%;
    min-height: 100%;
    max-height: unset;
}

:global .MuiDateCalendar-root {
    width: 100% !important;
}

.MuiDayCalendar-monthContainer {
    display: grid;
    gap: 20px;
}

:global .MuiDayCalendar-weekContainer {
    justify-content: space-around !important;
}

:global .MuiDayCalendar-header {
    justify-content: space-around !important;
}

:global .Mui-selected {
    background-color: #E31340 !important;
    color: #FFFFFF;
}

:global .MuiPickersDay-today {
    border: 1px solid #E31340 !important;
}

.MuiDayCalendar-monthContainer button {
    border-radius: 0;
}

@media (max-width: 768px) {
    .dialog {
        width: 100%;
        background-color: #FFFFFF;
        border: 1px solid #E1E1E1;
        color: #000;
        padding: 10px 1px;
        position: relative;
    }

    .calendar {
        width: 100%;
        height: 100%;
        max-height: unset;
    }

    .MuiDayCalendar-monthContainer {
        display: grid;
        gap: 20px;
        width: 100%;
    }

    .MuiDayCalendar-monthContainer button {
        border-radius: 0;
    }
}

@media (max-width: 400px) {
    .dialog {
        max-width: 94dvw;
    }
}
