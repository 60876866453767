
.wrapper {
    padding-top: 220px;
    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: scroll;
    z-index: 5;
}

.header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 52px;
    font-weight: 700;
    line-height: 63px;
    text-align: center;
    z-index: 5;
}

.middle_icon {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    z-index: 5;
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    z-index: 1;
}

.wrapper::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120vh;
    z-index: 2;
    border-radius: 10px;
    background-color: rgba(6, 39, 98, 0.5);
    pointer-events: none;
}
.dotted_line {
    width: 40%;
    height: 1px;
    margin: 40px 0;
    border-bottom: 1px dotted #ffffff;
    z-index: 5;
}

.button_home {
    width: 212px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    border-radius: 20px;
    background: #E31240;
    margin-bottom: 25px;
    padding: 15px 0;
    cursor: pointer;
    z-index: 5;
}

.description {
    font-size: 30px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    margin-bottom: 50px;
    z-index: 5;
}

.sub_descirption {
    width: 100%;
    max-width: 800px;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-bottom: 30px;
    z-index: 5;
}

.icons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    z-index: 5;
}

@media (min-width: 800px) and (max-width: 1900px) {
    .wrapper::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 120vh;
        z-index: 2;
        border-radius: 10px;
        background-color: rgba(6, 39, 98, 0.5);
        pointer-events: none;
    }
}

@media (min-width: 2000px) {
    .wrapper::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 2;
        border-radius: 10px;
        background-color: rgba(6, 39, 98, 0.5);
        pointer-events: none;
    }
}

@media (min-width: 2100px) {
    .wrapper::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 2;
        border-radius: 10px;
        background-color: rgba(6, 39, 98, 0.5);
        pointer-events: none;
    }
}