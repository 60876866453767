.wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header_util>div>span {
    width: max-content;
    @media(max-width: 1080px) {
        width: unset;
    }
}