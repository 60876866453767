.wrapper {
    width: 92dvw;
    margin: 0 15px 15px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-shrink: 0;
    background: #F8F8F8;
    border-radius: 10px;
    padding: 20px 10px;
}

.main_text {
    color:#062762;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    @media (max-width: 768px) {
        margin-top: 0;
        font-size: 18px;
    }
}

.red_text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #E31240;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    gap: 5px;
    cursor: pointer;
}

@media (min-width: 1023px) {
    .wrapper {
        width: 100%;
        margin: 0 15px 15px 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        flex-shrink: 0;
        background: #F8F8F8;
    }

    .red_text {
        font-size: 16px;
    }
}

@media (min-width: 2000px) {
    .wrapper {
        margin-bottom: 30px;
    }
}
