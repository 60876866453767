.wrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    z-index: 3;
}