.wrapper {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.box {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    place-items: center;
    margin-left: -7px;
    margin-bottom: 50px;
}

.image {
    width: 120px;
    height: 90px;
    border-radius: 10px;
}

.content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 120px));
    gap: 5px;
    justify-content: center;
    place-items: center;
    margin-top: 135px;
}

@media (min-width: 1023px) {
    .content {
        width: 50%;
        margin-top: 200px;
        grid-template-columns: repeat(3, 1fr);
    }
    .image {
        width: 437px;
        height: 320px;
    }
}