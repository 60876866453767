.wrapper {
    display: flex;
    justify-content: center;
    padding: 8px 16px;
    align-items: center;
    color: #000000;
    gap: 128px;
    border-radius: 5px;
    border: 1px solid #E1E1E1 ;
    background: #FFF;
    margin-bottom: 5px;
}

.active {
   border: 1px solid #E31240;
}

@media (max-width: 395px) {
    .wrapper {
        padding: 6px 10px;
    }
}