.wrapper {
    width: 100%;
    margin-top: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

.header {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    text-align: left;
    margin-bottom: 10px;
}

@media (min-width: 1023px) {
    .wrapper {
        padding: 0 5vw;
    }
}