.wrapper {
    width: 580px;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 5px;
}

.big_photo {
    width: 50%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.small_img_container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.small_photo {
    width: 100%;
    height: 50%;
    object-fit: cover;
}