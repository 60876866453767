.container {
    margin: auto;
    position: relative;
    max-width: 100vw;
}

.box {
    max-width: 100%;
    height: 100%;
    position: relative;
    margin: auto;
}

.imageContainer {
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.image {
    width: 100%;
    height: 100%;
}

.overlayPic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #06276299;
    background-repeat: no-repeat;
    z-index: 1;
    overflow: hidden;
}

.overlayText1 {
    font-weight: bold;
    color: #FFFFFF;
    z-index: 2;
    font-size: 52px;
}

.overlayText2 {
    font-weight: bold;
    color: #FFFFFF;
    z-index: 2;
    font-size: 52px;
    word-wrap: break-word;
    white-space: normal;
}

.overlay {
    position: absolute;
    top: 54%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.icon {
    margin: 60px 0;
}

.button {
    padding: 50px 0 0 0;
    width: 212px;
    height: 50px;
}

.additionalInfo {
    text-align: center;
    margin: 20px 0 80px 0;
    width: 362px;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.next_info {}

.additionalInfo1 {
    width: 362px;
    font-size: 20px;
    margin-bottom: 20px;
}

.additionalInfo2 {
    width: 300px;
    font-size: 20px;
}

@media (max-width: 990px) {
    .overlayText1 {
        font-weight: bold;
        color: #FFFFFF;
        z-index: 2;
        font-size: 44px;
    }

    .overlayText2 {
        font-weight: bold;
        color: #FFFFFF;
        z-index: 2;
        font-size: 44px;
        word-wrap: break-word;
        white-space: normal;
    }
}

@media (max-width: 768px) {
    .container {
        margin: auto;
        position: relative;
        width: 100%;
        max-width: 412px;

    }

    .next_info {
        width: 100%;
        font-family: Montserrat, serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

    }

    .box {
        padding-top: 55px;
        width: 412px;
        height: 100vh;
        position: relative;
        margin: auto;
    }

    .imageContainer {
        width: 100%;
        height: 100vh;
        overflow: hidden;
        position: relative;
    }

    .image {
        width: 100%;
        height: 100vh;
    }

    .text_main {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .overlayPic {
        padding-top: 55px;
        position: absolute;
        top: 0;
        left: 0;
        width: 412px;
        height: 100vh;
        background-color: #06276299;
        background-repeat: no-repeat;
        z-index: 1;
        overflow: hidden;
    }

    .overlayText1 {
        color: #FFF;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
    }

    .overlayText2 {
        font-weight: bold;
        color: #FFFFFF;
        z-index: 2;
        font-size: 26px;
        text-align: center;
    }

    .overlay {
        position: absolute;
        padding: 50px 20px 0 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 412px;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 2;
    }

    .end_purchase {
        width: 85%;
        height: 340px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #E31340;
        border-radius: 10px;
        padding: 20px;
    }

    .end_purchase_header {
        color: #FFF;
        text-align: center;
        font-size: 2px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        margin-bottom: 10px;
    }

    .end_purchase_sub_header {
        color: #FFF;
        font-family: Montserrat, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 20px;
    }

    .enum {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;
    }

    .enum_text {
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        width: 85%;
    }

    .add_box {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    .download_tickets {
        width: 90%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 20px;
        color: #E31240;
        background: #FFFFFF;
    }

    .icon {
        margin: 15px 0;
    }

    .button {
        padding: 50px 0 0 0;
        width: 218px;
        height: 50px;
    }

    .additionalInfo {
        text-align: center;
        margin: 20px 0 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .additionalInfo1 {
        width: 320px;
        font-size: 18px;
        margin: 0 0 20px 0;
    }

    .additionalInfo2 {
        width: 100%;
        font-size: 18px;
    }

    .text_sub {
        color: #FFF;
        font-family: Montserrat, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
        text-align: center;
    }

    .text_block {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 10px;
        color: #FFF;
        font-family: Montserrat, sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 20px;
    }

    .sub_description {
        font-family: Montserrat, serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        text-align: center;
    }

    .download_app {
        width: 100%;
        font-family: Montserrat, serif;
        font-size: 22px;
        font-weight: 400;
        line-height: 32px;
        text-align: center;
        margin: 30px 0 10px 0;
    }

    .sub_descirption {
        font-family: Montserrat ,serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 21px;
        text-align: center;
    }

    .icon_wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px 0;
    }
}

@media (max-width: 390px) {
    .overlay {
        width: 360px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-top: 40px;
    }

    .icon_wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
    }

    .overlayPic {
        height: 100vh;
    }
}