.wrapper {
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid red;
    margin: 1px 2px;

}

.number {
    color: #E31240;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}