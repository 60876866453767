.wrapper {
    width: 100vw;
    height: 200px;
    display: flex;
    margin-top: 50px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.header {
    color: #E31240;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 30px;
}

.sub_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
    margin-bottom: 30px;

}

.text {
    color: #1D1B20;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
}

@media (min-width: 1023px) {
    .header {
        font-size: 20px;
    }

    .text {
        font-size: 20px;
    }
}