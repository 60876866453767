@media (min-width: 768px) {
.box {
margin: auto;
max-width: 100vw;
height: 531px;
position: relative;
margin-top: 2px;
}
.imageContainer {
width: 100%;
height: 100%;
overflow: hidden;
margin: auto;
}
.image {
width: 100%;
height: 100%;
object-fit: cover;
object-position: center 119%;
background-repeat: repeat;
transform: scale(1.6);
margin: auto;
}
.overlayPic {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: #062762E3;
background-repeat: no-repeat;
z-index: 1;
overflow: hidden;
}
.overlay {
position: absolute;
width: 1360px;
height: 531px;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: flex-start;
z-index: 2;
}
.boxHeader {
position: relative;
width: 1360px;
display: flex;
justify-content: space-between;
align-items: center;
flex-shrink: 0;
z-index: 2;
}
.boxBody {
display: flex;
flex-direction: column;
width: 1360px;
}
.gridItem {
display: flex;
justify-content: space-between;
align-items: center;
width: 1360px;
}
.text{
width: 573px;
}
.logo {
width: 153.3px;
height: auto;
max-width: 154px;
max-height: 80px;
}
.phoneEmail {
display: flex;
justify-content: space-between;
}
.icons {
display: flex;
align-items: center;
font-weight: bold;
font-size: 24px;
white-space: nowrap;
}
.socialIconsDesktop {
display: flex;
align-items: center;
font-weight: bold;
font-size: 24px;
white-space: nowrap;
}
.socialIconsMobile {
display: none;
}
.rights {
width: 573px;
display: flex;
justify-content: space-around;
align-items: center;
}
}
@media (max-width: 1680px) {
.box {
max-width: 1680px;
margin: auto;
height: 631px;
position: relative;
margin-top: 2px;
}
.imageContainer {
width: 1680px;
height: 631px;
overflow: hidden;
}
.image {
width: 1680px;
height: 631px;
object-fit: cover;
object-position: center -200px;
}
.overlay {
width: 1060px;
height: 631px;
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: flex-start;
z-index: 2;
}
.boxHeader {
position: relative;
width: 1060px;
z-index: 2;
}
.boxBody {
display: flex;
flex-direction: column;
width: 1060px;
}
.gridItem {
display: flex;
justify-content: space-between;
align-items: center;
width: 1060px;
}
.rights {
width: 573px;
display: flex;
flex-direction: column;
align-items: start;
}}

@media (max-width: 1280px) {
.box {
margin: auto;
height: 631px;
position: relative;
max-width: 1280px;
margin-top: 2px;
}
.imageContainer {
width: 1280px;
height: 631px;
overflow: hidden;
}
.image {
width: 1280px;
height: 631px;
object-fit: cover;
object-position: center -200px;
}
.overlay {
width: 960px;
height: 631px;
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: flex-start;
z-index: 2;
}
.boxHeader {
position: relative;
width: 960px;
z-index: 2;
}
.boxBody {
display: flex;
flex-direction: column;
width: 960px;
}
.gridItem {
display: flex;
justify-content: space-between;
align-items: center;
width: 960px;
}
.rights {
width: 573px;
display: flex;
flex-direction: column;
align-items: start;
}}


@media (max-width: 990px) {
.socialIconsMobile {
display: block;
padding: 0 0 1vh 10px;
margin-top: 2px;
}
.phoneEmail {
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: start;
padding: 0 0 1vh 10px;
}
.icons {
display: flex;
align-items: center;
font-weight: bold;
font-size: 24px;
margin-bottom: 10px;
}
.socialIconsDesktop{
display: none;
}
.box {
margin: auto;
height: 631px;
position: relative;
max-width: 990px;
margin-top: 2px;
}
.imageContainer {
width: 990px;
height: 631px;
overflow: hidden;
}
.image {
width: 990px;
height: 831px;
object-fit: cover;
object-position: center -200px;
}
.overlay {
width: 660px;
height: 631px;
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: flex-start;
z-index: 2;
}
.boxHeader {
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: flex-start;
position: relative;
width: 660px;
z-index: 2;
}
.boxHeaderLogo {
display: flex;
flex-direction: column;
align-items: flex-start;
}
.logo {
width: 149.1px;
height: 60px;
margin: 1vh 0;
}
.boxBody {
display: flex;
flex-direction: column;
width: 660px;
}
.gridItem {
display: flex;
justify-content: space-between;
align-items: center;
width: 660px;
}
.rights {
width: 573px;
display: flex;
flex-direction: column;
align-items: start;
}}


@media (max-width: 768px) {
.box {
margin: auto;
width: 412px;
height: 648px;
position: relative;
margin-top: 1px;
}
.imageContainer {
width: 412px;
height: 648px;
overflow: hidden;
}
.image {
width: 412px;
height: 648px;
object-fit: cover;
object-position: bottom center;
/* transform: scale(0.3); */
}
.overlayPic {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: #062762E3;
background-repeat: no-repeat;
z-index: 1;
overflow: hidden;
}
.overlay {
padding: 5px 10px 10px 30px;
position: absolute;
top: 0;
left: 0;
width: 412px;
height: 648px;
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: flex-start;
z-index: 2;
}
.boxHeader {
position: relative;
display: flex;
flex-direction: column;
justify-content: center;
z-index: 2;
}
.logo {
width: 198.8px;
height: auto;
margin-bottom: 10px;
}
.phoneEmail {
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: start;
}
.icons {
display: flex;
align-items: center;
font-weight: bold;
font-size: 24px;
margin-bottom: 10px;
}
.socialIconsDesktop {
display: none;
}
.socialIconsMobile {
display: flex;
font-weight: bold;
font-size: 24px;
white-space: nowrap;
}
.boxHeaderLogo {
display: flex;
align-items: flex-start;
}
.boxBody {
margin-top: -20px;
display: flex;
flex-direction: column;
align-items: center;
width: 335px;
}
.gridItem {
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: start;
width: 335px;
}
.text{
margin: 10px 0;
}
.rights {
width: 335px;
display: flex;
flex-direction: column;
align-items: start;
margin-bottom: 20px;
}
.rightsFirst {
display: none;
}
.rightsSecond {
display: none;
}}
