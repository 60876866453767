.typography {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
}

.pen {
    color: #E6264C;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-left: 10px;
    cursor: pointer;
}

.discount_error {
    height: 20px;
    padding-top: 1px;
    color: #e31340;
    font-size: 14px;
    font-weight: 400;
    margin-top: -20px;
    margin-bottom: 10px;
    margin-left: 10px;
}

@media (min-width: 769px) {
    .middle_show_only {
        display: none;
    }

    .desktop_show_only {
        display: block;
    }

    .line {
        width: 283px;
        height: 1px;
        border-top: 0.5px dotted rgb(89, 89, 89);
        margin: 10px 0 10px 20px;
    }

    .line2 {
        width: 284.5px;
        height: 1px;
        border-top: 0.5px dotted rgb(89, 89, 89);
        margin: 10px 0;
    }

    .line3 {
        width: 1px;
        height: 170px;
        border-left: 0.5px dotted rgb(89, 89, 89);
        margin: 10px 10px 0px 0;
    }

    .line4 {
        width: 1px;
        height: 210px;
        border-left: 0.5px dotted rgb(89, 89, 89);
        margin: 20px 10px 20px 0px;
    }

    .box {
        width: 1263px;
        height: 317px;
        flex-shrink: 0;
        background-color: #F8F8F8;
        border-radius: 10px;
        padding: 48px 49px 47px 48px;
        display: flex;
    }

    .box_mutated {
        width: 1350px;
        height: 417px;
        flex-shrink: 0;
        background-color: #F8F8F8;
        border-radius: 10px;
        padding: 48px 49px 47px 48px;
        display: flex;
        margin: 0 auto 0px auto;
    }

    .billSmall {
        margin: 30px 10px;
        width: 80%;
        height: 190px;
        overflow: hidden;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #B9B9B9;
        border-radius: 10px;
        color: #000000;
        font-size: 16px;
        display: flex;
    }

    .billBig {
        margin: 30px 10px;
        width: 721px;
        height: 250px;
        overflow: hidden;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #B9B9B9;
        border-radius: 10px;
        color: #000000;
        font-size: 16px;
        display: flex;
    }

    .textField {
        width: 340px;
        height: 40px;
        margin: 10px 0px 30px 0px;
    }

    .logo {
        width: 109.34px;
        height: 44px;
        margin: 10px 20px;
    }

    .billPartOne {
        padding: 0px 15px 10px 0px;
    }

    .rowOne {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0px 0px 10px 0px;
    }

    .rowTwo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0px 4px 20px;
    }

    .rowTwoTwo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0px 10px 20px;
    }

    .rowThree {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5px 0px 5px 20px;
    }

    .billPartTwo {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding: 3px 15px 10px 0px;
    }

    .partAgreement {
        display: flex;
        flex-direction: column;
        padding: 0px 10px 15px 0px;
    }

    .titleTwo {
        display: flex;
        margin: 20px 0px 30px 0px;
    }

    .rowFour {
        display: flex;
        justify-content: space-between;
        margin: 3px 0px 10px 0px;
    }

    .rowFive {
        display: flex;
        justify-content: space-between;
        margin: 0px 0px 13px 0px;
    }

    .rowSix {
        display: flex;
        justify-content: space-between;
        margin: 0px 0px 5px 0px;
    }

    .titleThree {
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        letter-spacing: 0px;
    }

    .partTwo {
        margin: 60px 10px 10px 80px;
        display: flex;
        flex-direction: column;
    }

    .rowSale {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .rowAgreement {
        color: #000000;
        font-size: 22px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }

    .discountString {
        font-weight: 500;
        font-size: 16px;
    }

    .closedBox {
        width: 1255.2px;
        height: 84px;
        color: #B9B9B9;
        background-color: #F8F8F8;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 54px 0px 50.8px;
    }

    .rowBox {
        display: flex;
        align-items: center;
    }

    .saleAmount {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5px 0px 10px 20px;
    }
}

@media (min-width: 0px) and (max-width: 420px) {
    .line {
        width: 289px;
        height: 1px;
        border-top: 0.5px dotted rgb(89, 89, 89);
        margin: 10px 0;
    }

    .discount_error {
        margin-bottom: 10px;
    }

    .line2 {
        width: 244.5px !important;
        height: 1px;
        border-top: 0.5px dotted rgb(89, 89, 89);
        margin: 10px 0;
    }

    .line3 {
        width: 150px;
        height: 1px;
        border-top: 0.5px dotted rgb(89, 89, 89);
        margin: 20px 10px 20px 0px;
    }

    .line4 {
        width: 210px;
        height: 1px;
        border-top: 0.5px dotted rgb(89, 89, 89);
        margin: 20px 10px 20px 0px;
    }

    .billPartOne {
        width: 244.5px !important;
    }

    .billPartTwo {
        width: 244.5px !important;
        display: flex;
        flex-direction: column;
    }

    .closedBox {
        margin: auto;
        width: 284px !important;
        height: 18px;
        color: #B9B9B9;
        background-color: #F8F8F8;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 21px 22px 21px 25.8px;
    }

    .billSmall {
        margin: 20px 0;
        width: 100%;
        height: auto;
        overflow: hidden;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #B9B9B9;
        border-radius: 10px;
        color: #000000;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        padding: 16.25px 29.5px 64px 29px;
    }

    .billBig {
        margin: 20px 0;
        width: 90%;
        height: auto;
        overflow: hidden;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #B9B9B9;
        border-radius: 10px;
        color: #000000;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        padding: 16.25px 29.5px 64px 29px;
    }

    .box {
        border-radius: 10px;
        width: 90vw;
        /*width: 305px !important;*/
        flex-shrink: 0;
        background-color: #F8F8F8;
        padding: 0px !important;
        margin: auto;
        margin-left: -5px;
    }
}

@media (min-width: 769px) and (max-width: 1280px) {
    .middle_show_only {
        display: block;
    }

    .desktop_show_only {
        display: none;
    }

    .line {
        width: 283px;
        height: 1px;
        border-top: 0.5px dotted rgb(89, 89, 89);
        margin: 10px 0 10px 20px;
    }

    .line2 {
        width: 284.5px;
        height: 1px;
        border-top: 0.5px dotted rgb(89, 89, 89);
        margin: 10px 0;
    }

    .line3 {
        width: 1px;
        height: 170px;
        border-left: 0.5px dotted rgb(89, 89, 89);
        margin: 10px 10px 0px 0;
    }

    .line4 {
        width: 1px;
        height: 210px;
        border-left: 0.5px dotted rgb(89, 89, 89);
        margin: 20px 10px 20px 0px;
    }

    .box {
        width: 1087px;
        height: 317px;
        background-color: #F8F8F8;
        border-radius: 10px;
        padding: 48px 0px 47px 10px;
        display: flex;
    }

    .partTwo {
        width: 330px !important;
        margin: 0px 0px 10px 0px;
        display: flex;
        flex-direction: column;
    }

    .rowSale {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .closedBox {
        max-width: 990px;
        height: 84px;
        color: #B9B9B9;
        background-color: #F8F8F8;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 54px 0px 50.8px;
    }
}

@media (max-width: 768px) {
    .middle_show_only {
        display: none;
    }

    .line {
        width: 289px;
        height: 1px;
        border-top: 0.5px dotted rgb(89, 89, 89);
        margin: 10px 0;
    }

    .line2 {
        width: 284.5px;
        height: 1px;
        border-top: 0.5px dotted rgb(89, 89, 89);
        margin: 10px 0;
    }

    .line3 {
        width: 1px;
        height: 150px;
        border-left: 0.5px dotted rgb(89, 89, 89);
        margin: 10px 0px 0px 0px;
    }

    .line4 {
        width: 1px;
        height: 210px;
        border-left: 0.5px dotted rgb(89, 89, 89);
        margin: 20px 10px 20px 0px;
    }

    .box {
        position: relative;
        margin: auto;
        width: 100%;
        flex-shrink: 0;
        background-color: #F8F8F8;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
    }

    .billSmall {
        margin: 20px 0;
        width: 80%;
        height: auto;
        overflow: hidden;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #B9B9B9;
        border-radius: 10px;
        color: #000000;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        padding: 16.25px 29.5px 64px 29px;
    }

    .wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: -10px;
    }

    .billBig {
        margin: 20px 0;
        width: 80%;
        height: 590px;
        overflow: hidden;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #B9B9B9;
        border-radius: 10px;
        color: #000000;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        padding: 16.25px 29.5px 64px 29px;
    }

    .textField {
        width: 180px;
        height: 40px;
        margin: 10px 0px 30px 0px;
    }

    .logo {
        width: 84.31px;
        height: 44px;
    }

    .billPartOne {
        width: 284.5px;
    }

    .rowOne {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0px 0px 25px 0px;
    }

    .rowTwo {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;
        margin: 20px 0px 0px 0px;
    }

    .rowTwoTwo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5px 0px 10px 0px;
    }

    .rowThree {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0px 0px 0px;
    }

    .billPartTwo {
        width: 284.5px;
        display: flex;
        flex-direction: column;
    }

    .partAgreement {
        display: flex;
        flex-direction: column;
        padding: 0px 10px 15px 0px;
    }

    .titleTwo {
        display: flex;
        margin: 0px 0px 0px 0px;
    }

    .rowFour {
        display: flex;
        flex-direction: column;
        margin: 0px 0px 20px 0px;
    }

    .rowFive {
        display: flex;
        flex-direction: column;
        margin: 0px 0px 20px 0px;
    }

    .rowSix {
        display: flex;
        flex-direction: column;
        margin: 0px 0px 20px 0px;
    }

    .titleThree {
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        letter-spacing: 0px;
    }

    .partTwo {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
    }

    .rowSale {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .rowAgreement {
        color: #000000;
        font-size: 22px;
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }

    .discountString {
        font-weight: 600;
        font-size: 16px;
    }

    .closedBox {
        margin: auto;
        width: 324.2px;
        height: 18px;
        color: #B9B9B9;
        background-color: #F8F8F8;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 21px 22px 21px 25.8px;
    }

    .rowBox {
        display: flex;
        align-items: center;
    }

    .saleAmount {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5px 0px 10px 20px;
    }
}

@media (min-width: 1023px) {
    .box {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 1250px;
        height: 450px;
        flex-shrink: 0;
        background-color: #F8F8F8;
        border-radius: 10px;
    }

    .visit_conditions_wrapper {
        width: 100vw;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .billSmall {
        width: 100%;
        height: 260px;
    }

    .billBig {
        width: 100%;
    }

    .titleTwo {
        margin: 0;
    }

    .rowSix {
        flex-direction: column;
        margin: 0;
    }
}
