.box {
    margin: auto;
    position: relative;
    max-width: 100vw;
    height: 300px;
}

.imageContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: auto;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 25%;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #0627624D;
    background-repeat: no-repeat;
    z-index: 1;
}

.titles {
    position: absolute;
    top: 65%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #FFFFFF;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.subTitleKrovy {
    font-size: 52px;
    font-weight: bold;
}

.subTitle {
    font-size: 32px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .box {
        margin: auto;
        position: relative;
        max-width: unset;
        height: 450px;
    }

    .imageContainer {
        width: 100%;
        height: 450px;
        overflow: hidden;
        margin: auto;
    }

    .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center 40px;
    }

    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: #0627624D;
        background-repeat: no-repeat;
        z-index: 1;
    }

    .titles {
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #FFFFFF;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .subTitleKrovy {
        font-size: 26px;
        font-weight: bold;
        white-space: nowrap;
    }

    .subTitle {
        font-size: 22px;
        font-weight: bold;
        white-space: nowrap;
    }
}

@media (max-width: 420px) {
    .box {
        margin: auto;
        position: relative;
        max-width: unset;
        height: 450px;
    }

    .imageContainer {
        width: 100%;
        height: 450px;
        overflow: hidden;
        margin: auto;
    }
}

@media (min-width: 1023px) {
    .box {
        margin: auto;
        position: relative;
        max-width: 100vw;
        height: 550px;
    }

    .imageContainer {
        width: 100vw;
        height: 550px;
        overflow: hidden;
        margin: auto;
    }

    .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center 46%;
    }
}