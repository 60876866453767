.icon_prefixed_link {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    :global .MuiTypography-root {
        cursor: pointer;
        font-size: 24px;
    }
}
