.notice_bold {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #062762;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    margin: 20px 0;
}

.container {
    position: relative;
    margin: auto;
    background-color: #FFFFFF;
    max-width: 100vw;
}

.mainBox {
    position: relative;
    margin: auto;
    max-width: 1360px;
    background-color: #F8F8F8;
    margin-top: 40px;
    border-radius: 10px;
}

.titleBox {
    position: relative;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 56px 0 46px 0;
}

.titleString {
    color: #062762;
    font-size: 24px;
    font-weight: 600;
}

.subtitleBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    color: #000000;
    gap: 30px;
}

.subtitleText1 {
    text-align: center;
    width: 1256px;
}

.bold_text {

}

.subtitleText1_sub {
    color: #062762;
    font-size: 20px;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
}

.subtitleText2 {
    font-weight: 600;
    margin: 0 5px 0 10px;
}

.noticeBox {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0 30px 0;
}

.noticeWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.notice {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 20px 0 10px 0;
    max-width: 1149px;
}

.text {
    font-size: 20px;
    color: #000000;
}

.text_centered {
    font-size: 20px;
    color: #000000;
    text-align: center;
}

.text_centered_red {
    color: #E31240;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
}

.price_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}


.buttonBox {
    padding: 50px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.line {
    width: 1150px;
    height: 0px;
    border: 1px dashed #707070;
    opacity: 1;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.tourImgs {
    img {
        width: 100%;
        max-width: 400px;
        max-height: 250px;
        border-radius: 30px;
    }
}

@media (max-width: 768px) {
    .tourImgs {
        display: none;
    }
    .container {
        position: relative;
        margin: auto;
        background-color: #FFFFFF;
        max-width: 412px;
    }

    .subtitleText1_sub {
        width: 320px;
    }

    .notice_bold {
        color: #062762;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .mainBox {
        position: relative;
        margin: auto;
        max-width: 412px;
        background-color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .titleBox {
        position: relative;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0px;
    }

    .titleString {
        color: #062762;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
    }

    .subtitleBox {
        width: 352px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #000000;
        padding: 10px 30px;
    }

    .subtitleText1 {
        text-align: center;
        width: 656px;
    }

    .subtitleText2 {
        font-weight: 600;
        margin: 0 5px 0 10px;
    }

    .noticeBox {
        width: 362px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 10px 0;
    }

    .notice {
        padding: 0px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 350px;
        margin: 10px 0;
    }

    .text {
        font-size: 16px;
        color: #000000;
    }

    .textBlock {
        width: 270px;
    }

    .buttonBox {
        padding: 0px 0 20px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .line {
        width: 362px;
        height: 0px;
        border: 1px dashed #707070;
        opacity: 1;
        margin: 0 auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media (max-width: 768px) {
    .container {
        position: relative;
        margin: auto;
        background-color: #FFFFFF;
        max-width: 360px;
    }

    .mainBox {
        position: relative;
        margin: auto;
        max-width: 360px;
        background-color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .subtitleBox {
        width: 320px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #000000;
        padding: 10px 30px;
    }

    .subtitleText1 {
        font-size: 18px;
        text-align: center;
        width: 360px;
    }

    .line {
        width: 340px;
        height: 0px;
        border: 1px dashed #707070;
        opacity: 1;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    .noticeWrapper {
        padding-left: 10px;
    }

    .notice {
        padding: 0px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 340px;
        margin: 10px 0;
    }

    .notice_bold {
        padding: 0px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        width: 340px;
        margin: 10px 0;
    }
}
