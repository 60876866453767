.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #B9B9B9;
    padding: 20px 10px;
}

.header_text {
    color: #1D1B20;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: #1D1B20;
    padding: 0 10px;

}


@media (min-width: 1920px) {
    .wrapper {
        width: 1200px;
    }
}