.container {
    display: flex;
    overflow-x: scroll;
    gap: 3rem;
    padding-right: 3rem;
    height: min-content;
    flex-wrap: wrap;

    @media (max-width: 1400px) {
        gap: 1.5rem;
        padding-right: 1.5rem;
        >div {
            max-width: 350px;
        }
    }

    @media (max-width: 1000px) {
        flex-wrap: nowrap;
    }

    @media (max-width: 768px) {
        width: 100vw;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    @media (max-width: 420px) {
        overflow-x: unset;
    }
}
