.wrapper {
    width: 592px;
    height: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    color: #000000;
    border: 1px solid #B9B9B9;
    background: #FFF;
    margin-top: 20px;
    margin-left: 20px;
}

.content_left {
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.content_left_header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}


.icon {
    width: 109.34px;
    height: 44px;
    margin: 10px 20px;
}

.content_left_body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 35px;
}

.content_right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 24px ;
}

.content_personal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.perosnal_data_head {
    color: #000;
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.personal_data {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000;
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.perosnal_data_description_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.perosnal_data_description {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #79747E;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.perosnal_data_prop {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.red_button_change {
    color: #E6264C;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-left: 10px;
    cursor: pointer;
}

.vertical_line {
    width: 1px;
    height: 80%;
    border: 1px dashed #E1E1E1;
    stroke-width: 1px;
}

.hoziontal_line {
    width: 115px;
    height: 1px;
    border: 1px dashed #E1E1E1;
    stroke-width: 1px;
}

.hoziontal_line_mutated {
    width: 100px;
    height: 1px;
    border: 1px dashed #E1E1E1;
    stroke-width: 1px;
}
