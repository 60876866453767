.container {
    position: relative;
    margin: auto;
    background-color: #FFFFFF;
    max-width: 100vw;
    white-space: pre-line;
    padding: 18px;
}

.mainBox {
    position: relative;
    max-width: 1360px;
    background-color: #F8F8F8;
    margin: auto;
    border-radius: 10px;
    padding: 16px;
}

.titleBox {
    position: relative;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 56px 0 46px 0;
}

.titleString {
    color: #062762;
    font-size: 24px;
    font-weight: 600;
}

.subtitleBox {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    color: #000000;
}

.subtitleText1 {
    color: #062762;
    text-align: center;
}

.subtitleText2 {
    font-weight: 600;
    margin: 0 5px 0 10px;
}

.noticeBox {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0 30px 0;
}

.noticeWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.notice {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 20px 0 10px 0;
}

.text {
    font-size: 20px;
    color: #000000;
}

.red_text {
    color: #E31340;
}

.bold_text {
    font-weight: 700;
}

.capitalized_text {
    text-transform: capitalize;
}

.buttonBox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.line {
    max-width: 1055px;
    width: 90%;
    height: 0px;
    border: 1px dashed #707070;
    opacity: 1;
    margin: 20px auto;
}

.church_interior_img {
    max-width: 400px;
    border-radius: 30px;
    height: auto;
}

@media (max-width: 768px) {
    .container {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: auto;
        background-color: #FFFFFF;
    }

    .mainBox {
        position: relative;
        margin: auto;
    }

    .titleBox {
        position: relative;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0px;
    }

    .titleString {
        text-align: center;
        color: #062762;
        font-weight: 600;
    }

    .subtitleBox {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        padding: 10px 30px;
    }

    .subtitleText1 {
        text-align: center;
    }

    .subtitleText2 {
        font-weight: 600;
        margin: 0 5px 0 10px;
    }

    .noticeBox {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        margin: 10px 0;
    }

    .notice {
        padding: 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 10px 0;
    }

    .text {
        font-size: 16px;
    }


    .buttonBox {
        padding: 0 0 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .line {
        height: 0;
        border: 1px dashed #707070;
        opacity: 1;
        margin: 20px auto;
    }

    .church_interior_img {
        display: none;
    }
}

@media (max-width: 420px) {
    .mainBox {
        padding: 8px;
    }
    .subtitleBox {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        padding: 10px 30px;
    }

    .subtitleText1 {
        text-align: center;
        width: 100%;
    }

    .line {
        height: 0;
        border: 1px dashed #707070;
        opacity: 1;
        margin: 10px auto 20px;
    }

    .noticeWrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 18px;
    }
}

@media (min-width: 1023px) {
    .notice {
        padding: 0 10%;
    }
}