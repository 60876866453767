.wrapper {
    margin-top: 94px;
    position: relative;
    width: 100vw;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
}

.primary {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 75%;
    cursor: pointer;
    z-index: 20;
}

.overlayPic {
    position: absolute;
    width: 100%;
    height: 700px;
    top: 0;
    left: 0;
    background-color: #0627624D;
    background-repeat: no-repeat;
    z-index: 6;
}

.overlayPic::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    border-radius: 10px;
    background-color: rgba(6, 39, 98, 0.3);
    pointer-events: none;
}

.image {
    width: 100%;
    height: 700px;
    object-fit: cover;
    z-index: 5;
    object-position: center;
}


.text_wrapper {
    position: absolute;
    top: 0  ;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 5;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;

}

.list_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.middle_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text_inner {
    font-weight: bold;
    text-align: center;
    color: #FFFFFF;
    font-size: 52px;
    z-index: 1;
    white-space: normal;
    overflow: hidden;
    line-height: 63px;
}

.text_description {
    color: #FFF;
    font-size: 52px;
    font-style: normal;
    font-weight: 400;
    line-height: 63px;
}
