.wrapper {
    width: 100%;
    margin-top: -15px;
}

.list_item {
    color: #1D1B20;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    margin-left: -20px;
}

@media (max-width: 768px) {
    .wrapper {
        margin-left: -45px;
    }
    .list_item {
        font-size: 16px;
        margin-left: -1px;
    }
}


