.wrapper {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-top: -60px;
}

.wrapper_sub {
    width: 80%;
    display: flex;
    justify-content: center;
    gap: 50px;
    align-items: center;
}