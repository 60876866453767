.wrapper {
    position: absolute;
    top: 63%;
    left: 10px;
    width: 95vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.text {
    color: #FFF;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


@media (min-width: 1023px) {
    .wrapper {
        width: 100%;
        top: 35%;
        left: 33.5%;
        text-align: center;
        z-index: 7;
    }
}
