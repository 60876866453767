.wrapper {
    width: 100vw;
    max-width: 1920px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 20px;
}

.sidebar {
    width: 100%;
    max-width: 1800px;
    display: flex;
    justify-content: space-between;
    padding: 0 35px;
    align-items: center;
    gap: 20px;
}

.route_wrapper {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-right: 16px;
    gap: 15px;
}

.routes {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
    :global .MuiButtonBase-root {
        min-width: unset;
    }
}

.contact {
    white-space: nowrap;
}

.cart_container {
    width: 44px;
    height: 44px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: red;
}

.desktop_only {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
}

.mobile_only {
    display: none;
}


@media (max-width: 1475px) {
    .desktop_only {
        /*display: none;*/
    }
    .routes {
        display: none;
    }
    .mobile_only {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sidebar {
        padding: 0 10px;
        justify-content: space-between;
    }
}

@media (max-width: 1000px) {
    .desktop_only {
        display: none;
    }
}
