.wrapper {
    width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sub_wrapper {
    width: 100%;
    height: 100%;
    padding: 20px;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 20px auto 20px auto;
}


.content_block {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 22px;
}

.text_default {
    width: 100%;
    color: #000;
    font-size: 16px;
    font-style: normal;
    text-align: left;
    font-weight: 400;
    line-height: 20px;
}

.text_red {
    width: 100%;
    color: #E31240;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.text_red_bold {
    width: 100%;
    color: #E31240;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.text_extra_bold {
    width: 110%;
    color: #E31240;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.text_extra_bold_short {
    width: 50%;
    color: #E31240;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.text_mutated {
    width: 13%;
}

.small_description {
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color:  #1D1B20;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 22px;
}

.warning {
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.warning_icon {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

@media (min-width: 1023px) {
    .wrapper {
        width: 1402px;
        padding: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .sub_wrapper {
        width: 100%;
        height: 340px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .header {
        margin: 20px 0 20px 18%;
    }

    .text_extra_bold {
        width: 28%;
    }

    &&&.text_mutated {
        width: 17%;
    }

    .text_red_bold {
        font-size: 20px;
        font-weight: 700;
        line-height: 20px;
        text-align: left;

    }

    .text_default, .text_red {
        font-size: 20px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;

    }
}