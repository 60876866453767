.wrapper {
    position: relative;
    width: 100%;
    height: 320px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 10px;
}

.wrapper::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    border-radius: 10px;
    background-color: rgba(6, 39, 98, 0.80);
    pointer-events: none;
}

.header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 30px 0 10px 30px;
    z-index: 6;
}

.content_block {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0 10px 22px;
}

.line {
    width: 90%;
    height: 2px;
    background: #FFFFFF;
    border-radius: 10px;
    z-index: 6;
    margin: 10px auto 10px auto;
}

.image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 14px;
    z-index: 4;
}

.text_default {
    width: 100%;
    color: #FFFFFF;
    font-size: 16px;
    font-style: normal;
    text-align: left;
    font-weight: 400;
    line-height: 20px;
    z-index: 6;

}
.text_extra_bold {
    width: 50%;
    color: #FFFFFF;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    z-index: 6;
}

@media (min-width: 1023px) {
    .wrapper {
        width: 740px;
    }

    .text_extra_bold {
        width: 30%;
        color: #FFFFFF;
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        z-index: 6;
    }

    .header {
        text-align: left;
    }
}