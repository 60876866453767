.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.wrapper_modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.cross_wrapper {
    position: absolute;
    top: 10%;
    right: 30%;
}

.cross_wrapper_modal {
    cursor: pointer;
    position: absolute;
    top: 8%;
    right: 7%;
}

.wrapper_header {
    width: 100%;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #062762;
    margin: 10px 0;
}

.content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.content_header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    color: #062762;
}

.bold_end {
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    margin-top: 16px;
    color: #062762;
    justify-content: flex-start;
    text-align: left;
}

@media (max-width: 768px) {
    .wrapper_header {
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 20px;
        text-align: left;
    }

    .content_header {
        font-size: 18px;
    }

    .cross_wrapper_modal {
        position: absolute;
        top: 8%;
        right: 8%;
    }

    .bold_end {
        justify-content: center;
        text-align: left;
    }
}
