.pen {
    color: #E6264C;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    margin: 0;
}

.box {
    max-width: 1360px;
    width: 100%;
    background-color: #F8F8F8;
    border-radius: 10px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin: 0 auto;
    gap: 40px;
}

.line {
    width: 100%;
    height: 1px;
    border-top: 1px dotted #E1E1E1;
    margin: 10px 0 10px 20px;
}

.lineAlt {
    width: 100%;
    height: 1px;
    border-top: 1px dashed #B9B9B9;
    margin: 20px 0;
}

.infoContainer {
    width: 100%;
    overflow: hidden;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 10px;
    color: #000000;
    font-size: 16px;
    display: flex;
    flex-direction: column;
}

.partTwo {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 450px;

    :global .MuiFormControlLabel-root {
        margin: 0;
    }
}

.buttonToGo {
    height: 50px;
    position: relative;
    padding: 6px 12px;
    background-color: #E31340;
    color: white;
    display: flex;
    justify-content: center;
    border-radius: 25px;
}
