.wrapper {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto 50px 0;
}

.svg_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image {
    width: 90%;
    height: 380px;
    object-fit: cover;
}

@media (min-width: 1023px) {
    .wrapper {
        width: 1000px;
        height: 880px;
        flex-direction: row;
        padding: 0 200px;
        margin: 20px auto 90px auto;
    }
    .svg_wrapper {
        width: 1000px;
        /*overflow: hidden;*/
    }
    .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}