.wrapper {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 5;
    font-size: 14px;
    :global .MuiButtonBase-root {
        height: 65px;
        border-radius: 45px;
        flex-direction: row-reverse;
    }
}
