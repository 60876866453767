.wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px 20px 5px;
    -webkit-box-shadow: -2px -40px 43px 0px rgba(255,255,255,1);
    -moz-box-shadow: -2px -40px 43px 0px rgba(255,255,255,1);
    box-shadow: -2px -40px 43px 0px rgba(255,255,255,1);
}

.text {
    margin-right: 20px;
    color: #E31240;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
