@media (min-width: 769px) {
    .box {
        width: 1360px;
        height: 223px;
        flex-shrink: 0;
        background-color: #F8F8F8;
        border-radius: 10px;
        padding: 48px 49px 69px 50px;
    }

    .textField {
        width: 360px;
        height: 56px;
    }

    .textfieldBox {
        margin: 40px 4vw 0 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .title {
        color: #062762;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: flex-start;
    }

    .innerBoxRow {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .innerBox {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .textfieldRow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .closedBox {
        width: 100%;
        max-width: 1360px;
        height: 84px;
        color: #B9B9B9;
        background-color: #F8F8F8;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 54px 0px 50.8px;
    }

    .rowBox {
        display: flex;
        align-items: center;
    }

    .pen {
        width: 27px;
        height: 27px;
        cursor: pointer;
    }
}

@media (min-width: 769px) and (max-width: 1280px) {
    .box {
        max-width: 1087px;
        height: 223px;
        flex-shrink: 0;
        background-color: #F8F8F8;
        border-radius: 10px;
        padding: 19px 0px 53px 10px !important;
        width: 1087px;
    }

    .textField {
        width: 360px;
        height: 56px;
    }

    .textfieldBox {
        margin: 40px 1vw 0 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .title {
        color: #062762;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: flex-start;
    }

    .innerBoxRow {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .innerBox {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .textfieldRow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .closedBox {
        width: 990px;
        height: 84px;
        color: #B9B9B9;
        background-color: #F8F8F8;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 54px 0px 50.8px;
    }

    .rowBox {
        display: flex;
        align-items: center;
    }

    .pen {
        width: 27px;
        height: 27px;
        cursor: pointer;
    }

    .buttonToGo {
        min-width: 212px !important;
        height: 50px;
        margin: 0 25px 0 0 !important;
    }
}

@media (min-width: 0px) and (max-width: 420px) {
    .closedBox {
        height: 18px;
        color: #B9B9B9;
        background-color: #F8F8F8;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 21px 22px 21px 25.8px;
    }

    .box {
        border-radius: 10px;
        width: 100%;
        flex-shrink: 0;
        background-color: #F8F8F8;
        padding: 0px !important;
        margin: auto;
        margin-left: -5px;
    }

    .textField {
        width: 360px;
        height: 56px;
    }
}

@media (max-width: 768px) {
    .box {
        margin: auto;
        width: 100%;
        height: 600px;
        background-color: #F8F8F8;
        border-radius: 10px;
        padding: 19px 17px 35px 15px !important;
    }

    .textField {
        width: 100%;
        height: 56px;
    }

    .textfieldBox {
        margin: 30px 0 10px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .title {
        color: #062762;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: flex-start;
    }

    .innerBoxRow {
        display: flex;
        flex-direction: column;
    }

    .innerBox {
        display: flex;
        flex-direction: column;
    }

    .textfieldRow {
        display: flex;
        flex-direction: column;
    }

    .buttonToGo {
        position: relative;
        transform: translate(30%, 50%) !important;
    }

    .closedBox {
        margin: auto;
        height: 18px;
        color: #B9B9B9;
        background-color: #F8F8F8;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 21px 22px 21px 25.8px;
    }

    .rowBox {
        display: flex;
        align-items: center;
    }

    .pen {
        width: 18px;
        height: 18px;
        cursor: pointer;
    }
}


@media (min-width: 1023px) {
    .box {
        margin: auto;
        height: 280px;
        background-color: #F8F8F8;
        border-radius: 10px;
        padding: 19px 17px 35px 53px !important;
    }

    .button_wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin: auto 0 0 0;
    }

    .innerBoxRow {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
}

@media (min-width: 2000px) {
    .button_wrapper {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
    }
}