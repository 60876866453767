.wrapper {
    width: 50px;
    height: 112px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 10%;
    left: 80%;
    gap: 10px;
}

.button {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 400;
    color: #E31240;
    background: rgba(255,255,255, 0.7);
    border: 1px solid #E31240;
    border-radius: 5px;
}

@media (min-width: 1023px) {
    .wrapper {
        top: 5%;
        left: 73%;
    }
}