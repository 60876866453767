.container {
margin: auto;
position: relative;
max-width:100vw;
}
.box {
  max-width: 100%;
height: auto;
position: relative;
margin: auto;
}
.imageContainer {
  max-width: 100%;
height: 100%;
overflow: hidden;
position: relative;
}
.image {
width: 100%;
height: 100%;
object-position: 0px 140px;
transform: scale(1.6);
}
.overlayPic {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: #06276299;
background-repeat: no-repeat;
z-index: 1;
overflow: hidden;
}
.overlayText1 {
font-weight: bold;
color: #FFFFFF;
z-index: 2;
font-size: 52px;
}
.overlayText2 {
font-weight: bold;
color: #FFFFFF;
z-index: 2;
font-size: 52px;
}
.overlay {
position: absolute;
top: 54%;
left: 50%;
transform: translate(-50%, -50%);
width: 1360px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
z-index: 2;
}
.icon{
margin: 60px 0;
}
.button{
padding: 50px 0 0 0;
width: 212px;
height: 50px;
}
.additionalInfo {
text-align: center;
margin: 20px 0 80px 0;
width: 362px;
height: 120px;
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: center;
}
.additionalInfo1 {
  width: 362px;
font-size: 20px;
margin-bottom: 20px;
}
.additionalInfo2{
width: 300px;
font-size: 20px;
}
.button{
padding: 50px 0 0 0;
width: 212px;
height: 50px;
}

@media (max-width: 768px) {
.container {
margin: auto;
position: relative;
width: 412px;
}
.box {
padding-top: 55px;
width: 412px;
height: 100vh;
position: relative;
margin: auto;
}
.imageContainer {
width: 100%;
height: 100%;
overflow: hidden;
position: relative;
}
.image {
width: 100%;
height: 100%;
object-position: -315px 25px;
}
.overlayPic {
padding-top: 55px;
position: absolute;
top: 0;
left: 0;
width: 412px;
height: 100vh;
background-color: #06276299;
background-repeat: no-repeat;
z-index: 1;
overflow: hidden;
}
.overlayText1 {
font-weight: bold;
color: #FFFFFF;
z-index: 2;
font-size: 26px;
text-align: center;
}
.overlayText2 {
font-weight: bold;
color: #FFFFFF;
z-index: 2;
font-size: 26px;
text-align: center;
width: 200px;
}
.overlay {
position: absolute;
top: 58%;
left: 50%;
transform: translate(-50%, -50%);
width: 412px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
z-index: 2;
}
.icon{
margin: 18px 0;
}
.button{
padding: 50px 0 0 0;
width: 218px;
height: 50px;
}
.additionalInfo {
text-align: center;
margin: 20px 0 20px 0;
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: center;
}
.additionalInfo1{
width: 320px;
font-size: 18px;
margin: 0 0 20px 0;
}
.additionalInfo2{
width: 260px;
font-size: 18px;
}}