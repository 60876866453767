.wrapper {
    width: 100%;
    margin-top: 20px;
}

.container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
}

.wrapper_mutated {
    width: 1250px;
}

.box_mutated {}

@media (min-width: 1023px) {
    .wrapper {
        width: 54vw;
        height: 220px;
        padding: 10px;
    }

    .container {
        margin-top: 20px;
        grid-template-columns: repeat(3, 360px);
        place-content: center;
    }
}
