.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.content_text {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #1D1B20;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    text-decoration: none;
}

.link {
    color: #E31240;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    text-decoration: none;
}

@media (max-width: 768px) {
    .content_text, .link {
        font-size: 16px;
    }
}
