.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 79px;
    padding: 26px 11px 30px 11px;
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid #E1E1E1;
}

.boxWithoutIcons {
    width: 100%;
    height: 79px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 17px 15px 12px;
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid #E1E1E1;
    margin-bottom: -7px;
}

.title {
    color: #000000;
    font-weight: bold;
    font-size: 22px;
}

.subTitle {
    font-size: 13px;
    font-weight: 400;
    line-height: 15.85px;
    text-align: left;
    color: #000000;
}

.content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.innerRow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.imgBox {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.img {
    padding-right: 25px;
}

.wrapper_mutated {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 176px;
    padding: 26px 11px 30px 11px;
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid #E1E1E1;
}


@media (min-width: 1023px) {
    .wrapper, .boxWithoutIcons {
        width: 360px;
        height: 176px;
        /*margin-left: 20px;*/
    }
}