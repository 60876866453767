.wrapper {
    width: 1600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 100px auto 30px auto;
}

@media (max-width: 768px) {
    .wrapper {
        width: 380px;
    }
}