@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('src/assets/Montserrat-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('src/assets/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('src/assets/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('src/assets/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('src/assets/Montserrat-Bold.ttf') format('truetype');
}
header {
  padding: 0px !important;
  overflow: visible !important;
}

body {
  font-family: "Montserrat", 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: #fff;
  padding: 0 !important;
  overflow: visible !important;
  margin: 0;
}

h1, h2, h3, p {
  cursor: default;
  padding: 0 !important;
  overflow: visible !important;
}


