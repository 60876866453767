.footerWrapper {
    width: 100%;
    margin: auto;
    position: relative;
    max-width: 100vw;
}

.image {
    margin: auto;
    width: 100dvw;
    height: 100%;
    max-height: 530px;
    object-fit: cover;
    object-position: center 60%;
    background-repeat: repeat;
}

.imageReducedHeight {
    max-height: 260px;
}

.imageContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: auto;
}

.overlayPic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 540px;
    background-color: #062762E3;
    background-repeat: repeat;
    z-index: 1;
    overflow: hidden;
}

.footerContent {
    max-width: 1290px;
    padding: 47px;
    color: white;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    height: 100%;
}

.iconPrefixedLink {
    color: white;
    height: min-content;
}

.line {
    height: 1px;
    background: repeating-linear-gradient(
            to right,
            #ffffff 0,
            #ffffff 1px,
            transparent 1px,
            transparent 4px
    );
    margin: 30px 0;
}

.technology_partner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
    margin: 30px 0;
}

.technology_partner_text {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 11.72px;
    letter-spacing: 0.01em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.betrian_image {
    width: 140px;
}


@media (max-width: 1200px) {
    .footerContent {
        padding: 25px;
    }
    .image {
        height: 1070px;
        max-height: 1070px;
    }
    .overlayPic {
        max-height: 1075px;
        height: 1075px;
    }
    .imageReducedHeight {
        max-height: 470px;
    }
}

@media (max-width: 500px) {
    .line {
        margin: 10px 0;
    }
}
