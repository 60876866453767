.btn_purchase_tickets_popover {
    :global .MuiPaper-root {
        background-color: #E3E2E7;
        border-radius: 25px !important;
        width: 100%;
        max-width: 280px;
        padding: 8px 0;
    }
}

.purchase_tickets_popover_item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    padding: .5rem 1rem;

    &:hover {
        background-color: #d0ced7;
    }

    > * {
        cursor: pointer;
    }
}
