.wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.img_container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    border-radius: 10px;
    background-color: rgba(6, 39, 98, 0.5);
    pointer-events: none;
}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 5;
    position: absolute;
    top: 15%;

}

.img_container {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 4;
}

.image_done {
    position: absolute;
    top: -5px;
    left: 75%;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.description {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding-left: 20px;
    align-items: center;
    margin-bottom: 50px;
}

.button {
    width: 90%;
    height: 128px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    margin-bottom: 50px;
    text-decoration: none;
    background: #E31240;
}

.text {
    color: #FFFFFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    white-space: pre-wrap;
}

.text_description {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 10px;
}

.description_small {
    width: 100%;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text_small {
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
}

.text_small_bold {
    text-align: center;
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
}
