@media (min-width: 768px) {
.wrapper {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
max-width: 900px;
max-height: 700px;
background-color: #FFFFFF;
border: 1px solid #000;
color: #000;
padding: 40px;
overflow: auto;
}
.wrapper h2 {
margin-bottom: 31px;
}
.wrapper h2 > p {
margin: 0;
}
.wrapper p:last-of-type {
margin-bottom: 0;
}}

@media (max-width: 768px) {
.wrapper {
position: absolute;
top: 50%;
left: 50%;
transform: translate(-52%, -50%);
width: 300px;
height: 420px;
background-color: #FFFFFF;
border: 1px solid #000;
color: #000;
padding: 40px;
overflow: auto;
}
.wrapper h2 {
margin-bottom: 31px;
}
.wrapper h2 > p {
margin: 0;
}
.wrapper p:last-of-type {
margin-bottom: 0;
}}
